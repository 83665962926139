import "./footer.css"

const Footer = () => {
    return (
        <div id="footer" >
                © {new Date().getFullYear()} All rights reserved | Made by Amiben Dholakiya
        </div>
    )
}

export default Footer;